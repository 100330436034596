import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {socialMediaGridExtraReducers} from '@Components/social-media/social-media-extra-reducers';
import type {SocialPost} from '@Components/social-media/post.vo';
import {PostState} from '@Components/social-media/post.vo';
import type {SocialAccount} from '@Components/social-media/account.vo';
import {AccountState} from '@Components/social-media/account.vo';
import type {AccountStateFilter, PostStateFilter} from '@Components/social-media/social-media.types';
import {AccountSortingMetric, PostSortingMetric} from '@Components/social-media/social-media.types';

export interface SocialMediaGridsState {
  posts: SocialPost[];
  postStateFilter: PostStateFilter;
  getDeletedPosts: boolean;
  postSorter: PostSortingMetric;
  havePostsLoaded: boolean;
  doRefetchGridForNewSorting: boolean;
  morePostsOnServer: boolean;
  accounts: SocialAccount[];
  accountStateFilter: AccountStateFilter;
  accountSorter: AccountSortingMetric;
  haveAccountsLoaded: boolean;
  searchTerm: string;
}

const getAccountsInitialFilter = (): AccountStateFilter => {
  const accountFilters: string[] = Object.values(AccountState);
  const initialFilter: Record<string, boolean> = {};

  accountFilters.forEach((accountFilter) => {
    initialFilter[accountFilter] = true;
  });

  return initialFilter as AccountStateFilter;
};

const getPostsInitialFilter = (): PostStateFilter => {
  const postStates: string[] = Object.values(PostState);
  const initialFilter: Record<string, boolean> = {};

  postStates.forEach((postState) => {
    initialFilter[postState] = true;
  });

  return initialFilter as PostStateFilter;
};

export const DEFAULT_POSTS_LIMIT = 30;

const initialState: SocialMediaGridsState = {
  posts: [],
  postStateFilter: getPostsInitialFilter(),
  getDeletedPosts: false,
  postSorter: PostSortingMetric.DATE_PUBLISHED,
  havePostsLoaded: false,
  doRefetchGridForNewSorting: false,
  morePostsOnServer: true,
  accounts: [],
  accountStateFilter: getAccountsInitialFilter(),
  accountSorter: AccountSortingMetric.DATE_CREATED,
  haveAccountsLoaded: false,
  searchTerm: '',
};

export const socialMediaGridsSlice = createSlice({
  name: 'socialMediaGrid',
  initialState,
  reducers: {
    updatePostFilter: (state, {payload}: PayloadAction<PostStateFilter>) => {
      doUpdatePostFilter(state, payload);
    },
    resetPostsGrid: (state) => {
      state.posts = initialState.posts;
      state.postStateFilter = initialState.postStateFilter;
      state.postSorter = initialState.postSorter;
      state.havePostsLoaded = initialState.havePostsLoaded;
      state.morePostsOnServer = initialState.morePostsOnServer;
    },
    resetPosts: (state) => {
      state.posts = initialState.posts;
      state.havePostsLoaded = initialState.havePostsLoaded;
    },
    updateGetDeletedPosts: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateGetDeletedPosts(state, payload);
    },
    updatePostSorter: (state, {payload}: PayloadAction<PostSortingMetric>) => {
      doUpdatePostSorter(state, payload);
    },
    updateAccountSorter: (state, {payload}: PayloadAction<AccountSortingMetric>) => {
      doUpdateAccountSorter(state, payload);
    },
    updateHavePostsLoaded: (state, {payload}: PayloadAction<boolean>) => {
      updatePostsHaveLoaded(state, payload);
    },
    updateDoRefetchGridForNewSorting: (state, {payload}: PayloadAction<boolean>) => {
      doUpdateDoRefetchGridForNewSorting(state, payload);
    },
    deleteSocialAccount: (state, {payload}: PayloadAction<number>) => {
      doDeleteSocialAccount(state, payload);
    },
    deleteBulkSocialAccounts: (state, {payload}: PayloadAction<number[]>) => {
      doDeleteBulkSocialAccounts(state, payload);
    },
    deleteSocialPost: (state, {payload}: PayloadAction<string>) => {
      doDeleteSocialPost(state, payload);
    },
    updateAccountsFilter: (state, {payload}: PayloadAction<AccountStateFilter>) => {
      doUpdateAccountFilter(state, payload);
    },
    updateSearchTerm: (state, {payload}: PayloadAction<string>) => {
      doUpdateSearchTerm(state, payload);
    },

    addSocialPostToGrid: (state, {payload}: PayloadAction<SocialPost>) => {
      doAddSocialPostToGrid(state, payload);
    },
  },
  extraReducers: socialMediaGridExtraReducers,
});

const doAddSocialPostToGrid = (state: SocialMediaGridsState, socialPost: SocialPost): void => {
  const tempPosts = state.posts;
  tempPosts.push(socialPost);
  state.posts = tempPosts;
};
const doUpdateSearchTerm = (state: SocialMediaGridsState, updatedSearchTerm: string): void => {
  state.searchTerm = updatedSearchTerm;
};
const doUpdateGetDeletedPosts = (state: SocialMediaGridsState, getDeletedPosts: boolean): void => {
  state.getDeletedPosts = getDeletedPosts;
};
const doDeleteSocialAccount = (state: SocialMediaGridsState, idAccount: number): void => {
  state.accounts = state.accounts.filter((account) => {
    return account.id !== idAccount;
  });
};
const doDeleteSocialPost = (state: SocialMediaGridsState, idPost: string): void => {
  state.posts = state.posts.filter((post) => {
    return post.id !== idPost;
  });
};
const doDeleteBulkSocialAccounts = (state: SocialMediaGridsState, idAccounts: number[]): void => {
  state.accounts = state.accounts.filter((account) => {
    return !idAccounts.includes(account.id);
  });
};
const doUpdateAccountSorter = (state: SocialMediaGridsState, payload: AccountSortingMetric): void => {
  state.accountSorter = payload;
};
const doUpdatePostSorter = (state: SocialMediaGridsState, payload: PostSortingMetric): void => {
  state.postSorter = payload;
};
const doUpdateAccountFilter = (state: SocialMediaGridsState, payload: AccountStateFilter): void => {
  state.accountStateFilter = payload;
};
const doUpdatePostFilter = (state: SocialMediaGridsState, payload: PostStateFilter): void => {
  state.postStateFilter = payload;
};

export const doUpdateDoRefetchGridForNewSorting = (state: SocialMediaGridsState, payload: boolean): void => {
  state.doRefetchGridForNewSorting = payload;
};

export const updateMorePostsOnServer = (state: SocialMediaGridsState, payload: boolean): void => {
  state.morePostsOnServer = payload;
};
export const updateSocialPosts = (state: SocialMediaGridsState, payload: SocialPost[]): void => {
  state.posts = payload;
};

export const updateAccountsHaveLoaded = (state: SocialMediaGridsState, payload: boolean): void => {
  state.haveAccountsLoaded = payload;
};

export const updatePostsHaveLoaded = (state: SocialMediaGridsState, payload: boolean): void => {
  state.havePostsLoaded = payload;
};

export const updateSocialAccounts = (state: SocialMediaGridsState, payload: SocialAccount[]): void => {
  state.accounts = payload;
};

export const {
  resetPostsGrid,
  resetPosts,
  updateGetDeletedPosts,
  updateAccountsFilter,
  deleteSocialAccount,
  deleteBulkSocialAccounts,
  deleteSocialPost,
  updatePostSorter,
  updateAccountSorter,
  updatePostFilter,
  updateHavePostsLoaded,
  updateSearchTerm,
  updateDoRefetchGridForNewSorting,
  addSocialPostToGrid,
} = socialMediaGridsSlice.actions;

export const socialMediaGridsReducer = socialMediaGridsSlice.reducer;
