import type {SocialMediaProfile, SocialMediaProfileLinksMap, ValidFormattedSocialMediaLinks} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {
  getSocialMediaTypeMap,
  initSocialMediaAccountsUsernames, validateAndFormatSocialMediaLinks,
} from '@Components/social-media-profile-links/social-media-profile-links-library';
import {useLoadSocialAccounts, useSocialMediaAccounts} from '@Components/social-media/social-media.hooks';
import type {SocialAccount} from '@Components/social-media/account.vo';
import {isUserLoggedIn} from '@Libraries/user.library';

export const useSocialMediaLinksValidations = (socialMediaLinks: SocialMediaProfileLinksMap): ValidFormattedSocialMediaLinks => {
  return validateAndFormatSocialMediaLinks(socialMediaLinks);
};

export const useAreAllSocialMediaLinksValid = (socialMediaLinks: Record<SocialMediaProfile, string>): boolean => {
  const {isValid} = useSocialMediaLinksValidations(socialMediaLinks);
  return isValid;
};

export const useSocialMediaLinksErrorStatesMap = (socialMediaLinks: Record<SocialMediaProfile, string>): Record<SocialMediaProfile, boolean> => {
  const {errorMap} = useSocialMediaLinksValidations(socialMediaLinks);
  return errorMap;
};

export const useSocialMediaLinksValidUrlsMap = (socialMediaLinks: Record<SocialMediaProfile, string>): Record<SocialMediaProfile, string> => {
  const {formattedUrls} = useSocialMediaLinksValidations(socialMediaLinks);
  return formattedUrls;
};

export const useAllSocialMediaAccountUsernames = (): Record<SocialMediaProfile, string[]> => {
  const socialMediaUsernamesMap = initSocialMediaAccountsUsernames();

  if (!isUserLoggedIn()) {
    return socialMediaUsernamesMap;
  }
  useLoadSocialAccounts();

  const accounts: SocialAccount[] = useSocialMediaAccounts();

  const socialMediaTypeMap = getSocialMediaTypeMap();

  accounts.forEach(({type, username}) => {
    const mainType = socialMediaTypeMap[type] as SocialMediaProfile | undefined;

    if (mainType) {
      const currentUsernames = socialMediaUsernamesMap[mainType];
      if (!currentUsernames.includes(username) && username !== '') {
        currentUsernames.push(username);
      }
    }
  });

  return socialMediaUsernamesMap;
};
