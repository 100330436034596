import type {ContainerQueryObject} from "@Hooks/useContainerQuery";
import type {LoadingStates} from "@Utils/loading.util";
import type {SocialMediaProfileLinksMap} from "@Components/social-media-profile-links/social-media-profile-links.types";
import {bannerContainerQuery} from "@Components/banner-v2/banner-v2.types";

export const EVENTS_HOME_IFRAME_JS_CLASS = 'js-events-home-iframe';
export const EVENTS_HOME_IFRAME_IN_MODAL_JS_CLASS = 'js-events-home-iframe-in-modal';

export const containerQuery: ContainerQueryObject = {
    small: {
        minWidth: 0,
        maxWidth: 500
    },

    emptyStateSmall: {
        minWidth: 0,
        maxWidth: 580
    },

    emptyStateMedium: {
        minWidth: 581,
        maxWidth: 750
    },

    emptyStateLarge: {
        minWidth: 751,
        maxWidth: 900
    }
    // emptyStateSmall: bannerContainerQuery.small,
    // emptyStateMedium: bannerContainerQuery.medium
}
export interface MystuffEventsHomeProps {
// add props here
}

export enum EventsHomeEditingStep {
    PAGE = 1,
    CONTACT = 2
}

export enum EventsHomePageLayout {
    LIST = 'list',
    CALENDAR = 'calendar'
}

export interface EventsHomePageStyle {
    color: string;
    fontFamily: string;
    imageURL?: string;
    coverPhotoURL?: string;
}

export interface PageDetailsFormData extends EventsHomePageStyle {
    title: string;
    description: string;
    doRemoveWatermark: boolean;
    doShowPastEvents: boolean;
    eventsHomeLayoutPreference: EventsHomePageLayout
}

export interface ContactDetailsFormData {
    email: string;
    phoneNumber: string;
    socialMediaProfilesInput: SocialMediaProfileLinksMap
}

export interface EventsHomeReduxState {
    isEditing: boolean;
    wizardCurrentStep: EventsHomeEditingStep;
    eventsHomeHashedId: string;
    pageDetailsFormData: PageDetailsFormData;
    contactDetailsFormData: ContactDetailsFormData;
    loadingState: LoadingStates;
    isFormInErrorState: boolean;
    recentEmailsUsed: string[];
    recentPhoneNumbersUsed: string[];
    isEmptyState: boolean;
    savedEventsScheduleVO?: UserEventsScheduleVO;
}

export interface UserEventsScheduleVO {
    hashedID: string;
    title: string;
    email: string;
    description?: string;
    doRemoveWatermark?: boolean;
    doShowPastEvents?: boolean;
    phoneNumber?: string;
    socialProfileLinks: Record<string, string>;
    primaryColor?: string;
    secondaryColor?: string;
    profilePictureURL?: string;
    coverPhotoURL?: string;
    fontFamily?: string;
    teamID?: string;
    brandID?: string | null;
    viewType?: EventsHomePageLayout;
}

export interface EventsHomePageMetaBackendResponse {
    userEventsSchedule?: UserEventsScheduleVO;
    emailSuggestions: string[];
    phoneNumberSuggestions: string[];
}