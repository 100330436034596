import {openCreateSignupFormModal, PANEL_ID} from '@Modals/create-signup-form-modal';
import type {CreateSignUpFormParams, CreateSignUpFormResponse} from '@Components/email-signup-form-grid/email-signup-form-grid.types';
import {noop} from '@Utils/general.util';

export const NEW_FORM_BTN_ID = 'new-form-cta';

export const openCreateEmailSignupFormDialog = (onCreateSignUpFormSuccess: VoidFunction = noop): void => {
  openCreateSignupFormModal({
    panelId: PANEL_ID,
    onCreateSignUpFormSuccess,
  });
};

export const pollForEmailSignupFormPreview = (id: string) => {
  if (typeof window.PMW.formMaker === 'undefined') {
    window.PMW.getJsAndCssAssetGroup('formMakerDialog', pollForEmailSignupPreview.bind(this, id));
  } else {
    pollForEmailSignupPreview(id);
  }
};

export const openPublishEmailSignupFormDialog = (id: string) => {
  if (typeof window.PMW.formMaker === 'undefined') {
    window.PMW.getJsAndCssAssetGroup('formMakerDialog', openPublishDialog.bind(this, id));
  } else {
    openPublishDialog(id);
  }
};

function pollForEmailSignupPreview(id: string): void {
  window.PMW.formMaker.grid.pollForEmailSignupFormPreview(id);
}

function openPublishDialog(id: string): void {
  window.PMW.formMaker.publishDialog.open(id);
}

export async function createAndOpenSignupForm(data: CreateSignUpFormParams): Promise<void> {
  try {
    const response = (await window.PMW.writeLocal('formmaker/createEmailSignupForm', {
      name: data.formName,
      organizationName: data.organizationName,
      mailingListIds: data.mailingListIds,
    })) as CreateSignUpFormResponse;

    data.onCreateSignUpForm(response.idEmailSignupForm);
  } catch (e) {
    data.onError();
  }
}
