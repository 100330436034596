/**
 * This file contains the asyncThunk creators for Brands in My Stuff
 * and any common functions that may be needed by Brands which call the thunk functions.
 *
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */


import {createAsyncThunk} from "@reduxjs/toolkit";
import {BRAND_ASSETS, BRAND_CONTENT_TYPE_ACTIVITY_LOG, getBrandFromList, getBrandsStateFromStore, isNameDifferentFromBrandName} from "@Libraries/brands-library";
import {executeThunk} from "@Utils/thunk.util";

export const getBrandsAndActiveBrandsForUser = createAsyncThunk(
    'getBrandsAndActiveBrandsForUser',
    async (_, {rejectWithValue}) => {
        try {
            return await PMW.readLocal('brand/getBrandsAndActiveBrands');
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addNewBrand = createAsyncThunk(
    'addNewBrand',
    async ({brandName}) => {
        return await PMW.writeLocal('brand/newBrand', {'name': brandName});
    }
);

export const addNewBrandFromWebsite = createAsyncThunk(
    'addNewBrandFromWebsite',
    async ({brandName}, {rejectWithValue}) => {
        try {
            return await PMW.writeLocal('brand/newBrandFromWebsite', {'name': brandName})
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
)

export const changeBrandName = createAsyncThunk(
    'changeBrandName',
    async ({brandId, newBrandName}) => {
        return await PMW.writeLocal('brand/updateBrandName', {'brandId': brandId, 'newName': newBrandName});
    }
);

export const deleteBrand = createAsyncThunk(
    'deleteBrand',
    async ({brandId, newActiveBrandId}) => {
        return await PMW.writeLocal('brand/deleteBrand', {'brandId': brandId, 'newActiveBrandId': newActiveBrandId});
    }
)

export const addBrandFont = createAsyncThunk(
    'addNewBrandFont',
    /**
     *
     * @param {Object} fontInfo
     * @return {Promise<*>}
     */
    async (fontInfo) => await PMW.writeLocal('brand/createNewBrandFont',
        {
            'brandId': fontInfo.brandId,
            'idfont': fontInfo.idfont,
            'fontFamily': fontInfo.fontFamily,
            'brandFontName': fontInfo.brandFontName,
            'fontType': fontInfo.fontType,
            'fontSize': fontInfo.fontSize,
            'defaultColor': fontInfo.defaultColor,
        })
);

export const addBrandColor = createAsyncThunk(
    'addBrandColor',
    async (colorInfo) => await PMW.writeLocal('brand/createNewBrandColor', colorInfo)
);

export const updateBrandFont = createAsyncThunk(
  'updateBrandFont',
    async (fontInfo) => {
      return await PMW.writeLocal('brand/updateBrandFont', fontInfo);
    }
);

export const updateBrandAsset = createAsyncThunk(
    'updateBrandAsset',
    async ({brandAssetType, brandAssetParams}) => {
        return await PMW.writeLocal(getAjaxRequestUriForUpdatingBrandAsset(brandAssetType), brandAssetParams);
    }
)

export const getContentForBrandContentType = createAsyncThunk(
 'getContentForBrandContentType',
    async ({brandId, brandContentType}) => {
        return await PMW.readLocal(getAjaxRequestUriForFetchingBrandContentType(brandContentType), {'brandId': brandId})
    }
)

export const deleteBrandAsset = createAsyncThunk(
  'deleteBrandAsset',
  async ({brandAssetType, idbrandAsset, brandId}) => {
      return await PMW.writeLocal(getAjaxRequestUriForDeletingBrandAsset(brandAssetType), {'idbrandAsset': idbrandAsset, 'brandId': brandId})
  }
);

export const uploadBrandImage = createAsyncThunk(
    'uploadBrandImage',
    /**
     *
     * @param {FormData} formData
     * @returns {Promise<*>}
     */
    async (formData) => {
        return await PMW.writeLocal('posterimage/brandLogoUpload', formData, 'json', {
            timeout: 7200000,
            cache: false,
            processData: false,
            contentType: false,
        });
    }
)

export const getBrandActivityLog = createAsyncThunk(
    'getBrandActivityLog',
    /**
     *
     * @param brandId
     * @return {Promise<*>}
     */
    async (brandId) => {
        return await PMW.readLocal('brand/getBrandActivity', {'brandId': brandId});
    }
);

export const changeActiveBrandId = createAsyncThunk(
    'changeActiveBrandId',
    /**
     *
     * @param {Object} [params]
     * @param {string} [params.newActiveBrandId]
     * @return {Promise<any>}
     */
    async (params) => {
        return await PMW.writeLocal('brand/updateActiveBrandId', {'brandId': params.newActiveBrandId})
    }
)

export const changePrimaryLogo = createAsyncThunk(
    'changePrimaryLogo',
    async ({idbrandLogo, brandId}) => {
        return await PMW.writeLocal('brand/changePrimaryLogo', {idbrandLogo, brandId});
    }
)

export const getAllBrandsWithAssets = createAsyncThunk('getAllBrandsWithAssets', async () => {
    return await PMW.writeLocal('brand/getAllBrandsWithAssets');
})

export const fetchBrandLogos = async (brandId) => await executeThunk(() => getBrandLogosThunkDispatch(brandId));

export const fetchBrandColors = async (brandId) => await executeThunk(() => getBrandColorsThunkDispatch(brandId));

export const fetchBrandFonts = async (brandId) => await executeThunk(() => getBrandFontsThunkDispatch(brandId));

/**
 *
 * @param {Object} ajaxOpts data to send to the server + any params that can be used in extra-reducers
 * @param {string} brandCreationSource The place in the UI where the brand flow started from .one of BRAND_CREATION_SOURCES.* constants
 * @returns {Promise<void>}
 */
export const createBrandWithActiveContext = async (ajaxOpts, brandCreationSource) => {
    await executeThunk(() => {
        return PMW.redux.store.dispatch(addNewBrand({...ajaxOpts, brandCreationSource: brandCreationSource}));
    });
}

/**
 *
 * @param {Object} ajaxOpts data to send to the server + any params that can be used in extra-reducers
 * @param {(error: T, message?: string, logError?: boolean) => void} onErrorCallback
 * @param {() => void} onSuccessCallback
 * @returns {Promise<void>}
 */
export const createBrandFromWebsite = async (ajaxOpts, onErrorCallback, onSuccessCallback) => {
    await executeThunk(() => {
        return PMW.redux.store.dispatch(addNewBrandFromWebsite({...ajaxOpts}));
    }, onSuccessCallback, (response) => {
        if (response.payload === 'unable_to_extract') {
            onErrorCallback(response.payload, i18next.t('pmwjs_brand_kit_create_failed'), false);
        } else {
            onErrorCallback(response.error, i18next.t('pmwjs_brand_kit_create_error'), false);
        }
    });
}

/**
 *
 * @param {string} brandId
 * @param {string} newBrandName
 * @returns {Promise<void>}
 */
export const editBrand = async (brandId, newBrandName) => {
    const brands = getBrandsStateFromStore().brands,
        brandToEdit = getBrandFromList(brandId, brands);

    if (isNameDifferentFromBrandName(brandToEdit, newBrandName)) {
        await executeThunk(() => PMW.redux.store.dispatch(changeBrandName({brandId: brandId, newBrandName: newBrandName})));
    }
}

const getBrandLogosThunkDispatch = (brandId) => PMW.redux.store.dispatch(getContentForBrandContentType({brandId: brandId, brandContentType: BRAND_ASSETS.BRAND_LOGOS}));
const getBrandColorsThunkDispatch = (brandId) => PMW.redux.store.dispatch(getContentForBrandContentType({brandId: brandId, brandContentType: BRAND_ASSETS.BRAND_COLORS}));
const getBrandFontsThunkDispatch = (brandId) => PMW.redux.store.dispatch(getContentForBrandContentType({brandId: brandId, brandContentType: BRAND_ASSETS.BRAND_FONTS}));

/**
 *
 * @param {number} brandAssetType one of BRAND_ASSETS._* constants
 * @return {string}
 */
const getAjaxRequestUriForDeletingBrandAsset = (brandAssetType) => {
    switch (brandAssetType) {
        case BRAND_ASSETS.BRAND_LOGOS:
             return 'brand/deleteBrandLogo';
        case BRAND_ASSETS.BRAND_COLORS:
            return 'brand/deleteBrandColor';
        case BRAND_ASSETS.BRAND_FONTS:
            return 'brand/deleteBrandFont';
    }
}

/**
 *
 * @param {number} brandAssetType one of BRAND_ASSETS._* constants
 * @return {string}
 */
export const getAjaxRequestUriForUpdatingBrandAsset = (brandAssetType) => {
    switch (brandAssetType) {
        case BRAND_ASSETS.BRAND_FONTS:
            return 'brand/updateBrandFont';

        case BRAND_ASSETS.BRAND_COLORS:
            return 'brand/updateBrandColor';
    }
}

/**
 * @param brandContentType One of BRAND_ASSETS.* or brand_content_type_activity_log constants
 * @returns {string}
 */
export const getAjaxRequestUriForFetchingBrandContentType = (brandContentType) => {
    switch (brandContentType) {
        case BRAND_ASSETS.BRAND_LOGOS:
            return 'brand/getBrandLogos';
        case BRAND_ASSETS.BRAND_COLORS:
            return 'brand/getBrandColors';
        case BRAND_ASSETS.BRAND_FONTS:
            return 'brand/getBrandFonts';
        case BRAND_CONTENT_TYPE_ACTIVITY_LOG:
            return 'brand/getBrandActivity';
    }
}